<template>
  <div style="overflow: hidden;  width: 100%;">
    <MenuBar backgroundColor="#FFF" optionColor="#625858" logoLink=true borderColor="none" clickColor="#EAEAEA" style="z-index: 2;">
    </MenuBar>

    <div class="row">

      <div class="col-lg-5 col-md-12 col-sm-12 col-12 topConatiner">
        <h1 class="heading">Pay-as-you-go with Gorex Fuel Wallet</h1>
        <p class="subHeading">Digitally Transforming Your Fuel Experience</p>
        <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px;">
          <img class="andIcon" src="../assets/Icons/ios.svg" alt="">
          <img class="andIcon" src="../assets/Icons/android.svg" alt="">
        </div>
        <p class="subHeading">Scan into a Smarter Drive with Gorex digital fuel Payment solution Today!</p>

        <a href="/Contact-us" type="button" class="buttonStyle" style="margin-top: 35px;">
          <i class="buttonTxt">Register</i>
          <img class="arrowIcon" style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
        </a>
      </div>

      <div class="col-lg-7 col-md-12 col-sm-12 col-12 background-div">
        <img class="inner-image" src="../assets/Images/image 103.svg" alt="">
      </div>

    </div>

    <!-- <div style="width: 100vw; height: 30vw; display: flex; flex-direction:row;">

      <div style="width: 50vw; padding-block: 2vw; padding-inline: 4vw;">
        <h1 class="heading">Pay-as-you-go with Gorex Fuel Wallet</h1>
        <p class="subHeading">Digitally Transforming Your Fuel Experience</p>
        <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 1.5vw;">
          <img style="width: 12vw; margin-right: 3vw" src="../assets/Icons/ios.svg" alt="">
          <img style="width: 12vw;" src="../assets/Icons/android.svg" alt="">
        </div>
        <p class="subHeading">Scan into a Smarter Drive with Gorex digital fuel Payment solution Today!</p>
        <div type="submit" class="buttonStyle" style="margin-top: 50px;">
          <i class="buttonTxt">Register</i>
          <img style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
        </div>
      </div>

      <div style="width: 50vw; position: relative; ">
        <img style="width: 100%; position: absolute;" src="../assets/Images/bk-header-img (1).svg" alt="">
        <img style="position: absolute; width: 40vw; margin-top: 5vw; " src="../assets/Images/image 103.svg" alt="">
      </div>

    </div> -->


    <div class="four" v-motion-slide-visible-once-bottom>

      <div>
        <div class="herotxt">
          <h6 class="dash">-</h6>
          <h6 class="txt">About Gorex Fuel Wallet</h6>
        </div>
        <h6 class="head">Experience Great Convenience:</h6>
      </div>

      <div class="row"
        style="height: auto; display: flex; flex-direction: row; align-items: center; justify-content: space-evenly; margin-block: 20px;">

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-left: 2px solid #FF8B00; padding-inline: 30px;  margin-bottom: 15px;">
          <img style="width: 32.073px; height: 40px; margin-top: 7px;" src="../assets/Icons/Frame (1).svg" alt="">
          <h6 class="head-1">Paperless Transactions</h6>
          <p class="head-2">Seamless and Paperless Digital Payments with Gorex digital fuel Payment solution Service.</p>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-left: 2px solid #FF8B00; padding-inline: 30px; margin-bottom: 15px;">
          <img style="width: 32.073px; height: 40px; margin-top: 7px;" src="../assets/Icons/Frame (2).svg" alt="">
          <h6 class="head-1">Environmental Sustainability</h6>
          <p class="head-2">Contribute to environmental sustainability with Gorex digital fuel payment solution's paperless transactions.
          </p>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; background:linear-gradient(135deg, #FFF6EB 4.89%, rgba(255, 246, 235, 0.00) 96.69%); border-left: 2px solid #FF8B00; padding-inline: 30px;  margin-bottom: 15px;">
          <img style="width: 32.073px; height: 40px; margin-top: 7px;" src="../assets/Icons/Frame (3).svg" alt="">
          <h6 class="head-1">Real-Time Reconciliation</h6>
          <p class="head-2">Ensure financial accuracy with Gorex digital fuel payment solution's real-time reconciliation feature</p>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-left: 2px solid #FF8B00; padding-inline: 30px;  margin-bottom: 15px;">
          <img style="width: 32.073px; height: 40px; margin-top: 7px;" src="../assets/Icons/Frame (4).svg" alt="">
          <h6 class="head-1">Fuel Freedom</h6>
          <p class="head-2">Unparalleled freedom in fueling with nationwide acceptance and open-loop flexibility</p>
        </div>
      </div>

    </div>

    <div class="messageContainer row" v-motion-slide-visible-once-bottom>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-1 order-md-2 msgCon2 " v-motion-slide-visible-once-right>
        <img src="../assets/Images/Home_Image1.svg" class="msgImg" alt="">
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-2 order-md-1 msgCon1" v-motion-slide-visible-once-left>
        <h1 class="msgSubHead">Pay-as-You-Go</h1>

        <p class="msgText">Empower your fueling journey nationwide fuel acceptance with Gorex's innovative pay-as-you-go Fuel payment solution and experience flexible use across anywhere.</p>


        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Controlled Spending</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Nationwide Fuel Acceptance</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Real-time Expense Reconciliation</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Digital Paperless Transaction</h6>
        </div>

        <a  href="/Contact-us" type="button" class="buttonStyle" style="margin-top: 50px;">
          <i class="buttonTxt">Register</i>
          <img class="arrowIcon" style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
        </a>

      </div>

    </div>


    <div class="messageContainer row" v-motion-slide-visible-once-bottom>

      <div class="col-lg-6 col-md-6 col-sm-12 msgCon2" v-motion-slide-visible-once-left>

        <img src="../assets/Images/Home_Image2.svg" class="msgImg" alt="">

      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 msgCon1" v-motion-slide-visible-once-right>
        <h1 class="msgSubHead">Widespread Payment Flexibility</h1>

        <p class="msgText">Enjoy the freedom of a widely accessible solution with Gorex digital fuel payment solution, acceptable at multiple fuel stations across nation-wide Oil Marketing Companies (OMCs), ensuring limitless choices.</p>


        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Versatile Fueling Choices</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Access Across OMCs</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Limitless Options</h6>
        </div>

        <a href="/Contact-us" type="button" class="buttonStyle" style="margin-top: 50px;">
          <i class="buttonTxt">Register</i>
          <img class="arrowIcon" style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
        </a>

      </div>

    </div>


    <div class="messageContainer row" v-motion-slide-visible-once-bottom>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-1 order-md-2 msgCon2 ">
        <img src="../assets/Images/Home_Image3.svg" class="msgImg" alt="">
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-2 order-md-1 msgCon1">
        <h1 class="msgSubHead">Smart Digital Payment Solution Loyalty Program</h1>

        <p class="msgText">Participate in Gorex's Smart Digital Payment Solution Loyalty Program, enhancing the fueling experience with personalized benefits and incentives for responsible fuel credit usage.</p>


        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Personalized Fuel Rewards</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Exclusive Benefits</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Fuel Rewards</h6>
        </div>

        <a href="/Contact-us" type="button" class="buttonStyle" style="margin-top: 50px;">
          <i class="buttonTxt">Register</i>
          <img class="arrowIcon" style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
        </a>

      </div>

    </div>


    <div class="messageContainer row" v-motion-slide-visible-once-right>

      <div class="col-lg-6 col-md-6 col-sm-12 msgCon2"  v-motion-slide-visible-once-bottom>

        <img src="../assets/Images/image 111.svg" class="msgImg" alt="">

      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 msgCon1"  v-motion-slide-visible-once-left>
        <h1 class="msgSubHead">Corporate Fuel Empowerment</h1>

        <p class="msgText">Tailored solutions for corporate and SMEs with Gorex digital fuel payment solution, providing efficient fuel management tools and controls over employee fuel limits for streamlined business operations.</p>


        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Employee Spending</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Efficient Fleet Management</h6>
        </div>
        <div class="field">
          <img class="bullet" src="../assets/Icons/checkmark-features-ic.svg" alt="">
          <h6 class="bullettext">Corporate Fueling</h6>
        </div>

        <a href="/Contact-us" type="button" class="buttonStyle" style="margin-top: 50px;">
          <i class="buttonTxt">Register</i>
          <img class="arrowIcon" style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
        </a>

      </div>

    </div>


    <div class="messageContainer row" style="background: linear-gradient(57deg, #362380 24.02%, #4AD594 99.63%);" v-motion-slide-visible-once-right>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-1 order-md-2 msgCon2 " v-motion-slide-visible-once-right>
        <img src="../assets/Images/Home_Image4.svg" class="msgImg" alt="">
        
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-2 order-md-1 msgCon1" v-motion-slide-visible-once-right>
        <h1 class="msgSubHead" style="color: #FFF !important;">Smart Fuel Solutions for Digital Reconciliation & Payments</h1>

        <p class="msgText" style="color: #FFF !important;">Gorex digital fuel payment solution empowers corporate fueling with efficient management tools and controlled employee limits for streamlined operations.</p>


        <a href="/Contact-us" type="button" class="buttonStyle" style="margin-top: 50px;">
          <i class="buttonTxt">Register</i>
          <img class="arrowIcon" style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
        </a>

      </div>

    </div>

    <div class="four"  v-motion-slide-visible-once-bottom>

      <div>
        <div class="herotxt">
          <h6 class="dash">-</h6>
          <h6 class="txt">WHY GOREX?</h6>
        </div>
        <h6 class="head">Transforming Fuel Management Dynamics with Gorex</h6>
      </div>

      <div class="row"
        style="height: auto; display: flex; flex-direction: row; align-items: center; justify-content: space-evenly; margin-block: 20px;">

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-radius: 24px; background:linear-gradient(135deg, #FFF6EB 4.89%, rgba(255, 246, 235, 0.00) 96.69%); padding-inline: 30px;  padding-block: 30px;">
          <img style="width: 32.073px; height: 40px; margin-top: 7px;" src="../assets/Icons/Frame (6).svg" alt="">
          <h6 class="head-1">Tailored Corporate & SME's Solutions</h6>
          <p class="head-2">Choose Gorex for smart spending & tailored fuel solutions. Control limits responsibly,
            optimize efficiently, a top choice for businesses.</p>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-radius: 24px; padding-inline: 30px; background:linear-gradient(135deg, #FFF6EB 4.89%, rgba(255, 246, 235, 0.00) 96.69%);  padding-block: 30px;">
          <img style="width: 32.073px; height: 40px; margin-top: 7px;" src="../assets/Icons/Frame (7).svg" alt="">
          <h6 class="head-1">Liberty to Open Loop for Employee</h6>
          <p class="head-2">Empower your team with Gorex's Open Loop Liberty for nationwide fuel acceptance. Experience
            Paperless Digital Transactions and revolutionize convenience.
          </p>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-radius: 24px; background:linear-gradient(135deg, #FFF6EB 4.89%, rgba(255, 246, 235, 0.00) 96.69%);  padding-inline: 30px;   padding-block: 30px;">
          <img style="width: 32.073px; height: 40px; margin-top: 7px;" src="../assets/Icons/Frame (8).svg" alt="">
          <h6 class="head-1">Seamless Business Operations:</h6>
          <p class="head-2">Gorex digital fuel payment solution streamlines corporate fueling, providing comprehensive tools that enhance efficiency and simplify day-to-day business operations.</p>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; background: #FF8B00; border-radius: 24px; padding-inline: 30px;  padding-block: 30px;">
          <h6 class="head-1" style="color: #FFFFFF !important;">Connect with Gorex!</h6>
          <p class="head-2" style="color: #FFFFFF !important;">Connect with our expert team for personalized assistance
            anywhere, anytime.</p>

          <a href="/Contact-us" type="button" class="buttonStyle" style="border: 1px solid #FFFFFF; width: 150px !important; ">
            <img style="width: 15px; height: 14px;" src="../assets/Icons/white-contact-ic.svg" alt="">
            <i class="buttonTxt">Contact Us</i>

          </a>
        </div>
      </div>

    </div>


    <div class="four" style="background: #FEF1E6 !important; justify-content: center;"  v-motion-slide-visible-once-bottom>

      <div style="display: flex; justify-content: center;" v-motion-roll-visible-once-left>
        <h6 class="head">Partners love us</h6>
      </div>

      <div class="row" v-motion-roll-visible-once-right
        style="height: auto; display: flex; flex-direction: row; align-items: center; justify-content: space-around; margin-block: 20px;">

        <div class="col-lg-3 col-md-3 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-left: 2px solid #FF8B00; padding-inline: 30px; margin-bottom: 15px;">
          <h6 class="head-1" style="font-size: 48px !important; color: #FB9D4B !important;">50+</h6>
          <p class="head-2">Total Corporates
          </p>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; background:linear-gradient(135deg, #FFF6EB 4.89%, rgba(255, 246, 235, 0.00) 96.69%); border-left: 2px solid #FF8B00; padding-inline: 30px;  margin-bottom: 15px;">

          <h6 class="head-1" style="font-size: 48px !important; color: #FB9D4B !important;">1700+</h6>
          <p class="head-2">Total Users</p>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; border-left: 2px solid #FF8B00; padding-inline: 30px;  margin-bottom: 15px;">

          <h6 class="head-1" style="font-size: 48px !important; color: #FB9D4B !important;">110+</h6>
          <p class="head-2">Total Fuel Stations</p>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-12 col-12"
          style="display: flex; flex-direction: column; justify-content: center; background:linear-gradient(135deg, #FFF6EB 4.89%, rgba(255, 246, 235, 0.00) 96.69%); border-left: 2px solid #FF8B00; padding-inline: 30px;  margin-bottom: 15px;">

          <h6 class="head-1" style="font-size: 48px !important; color: #FB9D4B !important;">25+</h6>
          <p class="head-2">OMCs</p>
        </div>

      </div>
    </div>


    <div class="four"  v-motion-slide-visible-once-bottom>

      <div>
        <div class="herotxt">
          <h6 class="txt">WHY GOREX?</h6>
        </div>
        <h6 class="head">Navigate Fueling Efficiency Effectively</h6>
      </div>

      <div class="row" id="accordion" style="height: auto; display: flex; flex-direction: column; margin-block: 20px;">

        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button class="btn head-1" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                aria-controls="collapseOne">
                1- What sets Gorex digital fuel payment solution apart from traditional fleet wallets?
              </button>
            </h5>
          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body head-2">
              Gorex digital fuel payment solution offers advanced fuel payment services, providing flexibility, control, and efficient fuel management for optimal business and personal use.
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn head-1" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                aria-controls="collapseTwo">
                2- How does Gorex digital fuel payment solution benefit businesses and SMES?
              </button>
            </h5>
          </div>

          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body head-2">
              Gorex digital fuel payment solution tailors fuel wallet services, empowering businesses with efficient fuel management tools and controlled employee spending.
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn head-1" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                aria-controls="collapseThree">
                3- Can Gorex digital fuel payment solution be used at any fuel station?
              </button>
            </h5>
          </div>

          <div id="collapseThree" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body head-2">
              Yes, Gorex digital fuel payment solution works across multiple petrol pumps, offering seamless flexibility and convenience.
            </div>
          </div>
        </div>


        <div style="widows: 100%; display: flex; flex-direction: row; justify-content: center; margin-top: 35px; "  v-motion-slide-visible-once-bottom>
          <h6 class="txtStyle">Have more questions? </h6>
          <a href="/Contact-us" type="button" class="linkStyle">Contact Us</a>
        </div>


      </div>

    </div>


    <div class="messageContainer row" style="background: linear-gradient(57deg, #362380 24.02%, #4AD594 99.63%);"  v-motion-slide-visible-once-bottom>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-1 order-md-2 msgCon2 "  v-motion-slide-visible-once-left>
        <img src="../assets/Images/Home_Image5.svg" class="msgImg" alt="">
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-sm-2 order-md-1 msgCon1"  v-motion-slide-visible-once-right>
        <h1 class="msgSubHead" style="color: #FFF !important;">Smart Choices Begin with Gorex digital fuel payment solution</h1>

        <p class="msgText" style="color: #FFF !important;">Say goodbye to traditional fleet cards and hello to a new era of smart fueling.</p>


          <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px;">
          <img class="andIcon" src="../assets/Icons/ios.svg" alt="">
          <img class="andIcon" src="../assets/Icons/android.svg" alt="">
        </div>

      </div>

    </div>


    <div
      style="widows: 100%; display: flex; flex-direction:column; align-items: center; justify-content: center; margin-block: 35px; ">
      <h6 class="txtStyle">Join us in revolutionizing your journey with smart and eco-friendly fueling </h6>
      <a href="/Contact-us" type="button" class="buttonStyle" style="margin-top: 50px;">
        <i class="buttonTxt">Register</i>
        <img class="arrowIcon" style="width: 14.945px; height: 10.071px;" src="../assets/Icons/arrow.svg" alt="">
      </a>
    </div>


    <FooterBar></FooterBar>
  </div>

</template>

<script>
import MenuBar from './MenuBar.vue';
import FooterBar from './Footer.vue';
export default {
  name: 'HomeComponent',
  methods: {
    handleScroll() {
      const container = this.$refs.scrollContainer;
      const currentScroll = container.scrollTop;

      if (currentScroll > this.lastScrollTop) {
        // Scrolling down
        this.isScrollingUp = false;
      } else {
        // Scrolling up
        this.isScrollingUp = true;
      }

      this.lastScrollTop = currentScroll;
    },
  },

  data() {
    return {
      lastScrollTop: 0,
      isScrollingUp: false,
    };
  },
  components: {
    MenuBar,
    FooterBar
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.animate-on-scroll-up {
  transition: transform 0.3s ease-in;
}

.animate-on-scroll-up.animate {
  transform: translateY(-50px); /* Example translation */
}

.buttonStyle:hover{
  .arrowIcon{
    transform: translateX(7px);
  }
}
.topConatiner{
  padding-inline: 4vw; 
  padding-block: 7vw;
}
.background-div {
  /* width: 55%; */
  /* height: 40vh;  */
  background-image: url('../assets/Images/bk-header-img (1).svg');
  background-size: cover;
  background-position: center;
}

.inner-image {
  width: 45vw;
  margin-top: 5vw;
  margin-bottom: 2vw;
  margin-left: 5vw;
}

.andIcon{
  width: 150.981px; 
  height: 47.181px;
}

.linkStyle {
  color: #FF8B00;
  font-family: Aspekta;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-decoration: none;
}

.txtStyle {
  color: rgba(34, 40, 49, 0.60);
  text-align: center;
  font-family: Aspekta;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-right: 20px
}

.singleImg {
  width: 30vw;
  height: 30vw;
}

.field {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bullet {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.bullettext {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 8px;
}

.messageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  margin-bottom: 80px;
  margin-inline: 20px;

}

.msgCon1 {
  width: 100%;
  padding: 25px;
}

.msgHead {
  color: #FF8B00;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.msgSubHead {
  color: #171717;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
  justify-self: start;
}

.msgText {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: justify;
  margin-bottom: 35px;
}

.msgName {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.msgDes {
  color: rgba(23, 23, 23, 0.50);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.msgCon2 {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-block: 20px;
}

.msgImg {
  width: 35vw;
  /* height: 45vh; */
}

.msgImg1 {
  width: 10vw;
  height: 10vw;
  margin-top: 5vw;
  margin-left: 1vw;
  flex-shrink: 0;

}

.msgImg2 {
  width: 17vw;
  height: 17vw;
}

.four {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  background: #FAFAFA;
  margin-bottom: 80px;
  margin-inline: 20px;
  padding: 30px;

  .head {
    color: #171717;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .head-1 {
    color: #171717;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-block: 10px;
  }

  .head-2 {
    color: rgba(23, 23, 23, 0.50);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-block: 10px;
  }
}

.heading {
  color: #171717;
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 62px; */
  /* 155% */
  text-transform: capitalize;
  margin-block: 15px;
}

.subHeading {
  color: #171717;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-block: 30px;
}

.buttonStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* align-self: center; */
  padding: 12px 24px;
  margin-bottom: 10px;
  /* gap: 4px; */
  width: 130px;
  background-color: #FF8B00;
  border-radius: 50px;
  text-decoration: none;
}

.buttonTxt {
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.herotxt {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.dash {
  width: 20px;
  height: 0px;
  flex-shrink: 0;
  stroke-width: 2.5px;
  color: #FF8B00;
}

.txt {
  color: #FF8B00;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (max-width: 1310px) {
    .heading {
      font-size: 30px ;
      margin-block: 5px ;
    }

    .subHeading {
      font-size: 15px;
      margin-block: 10px;
    }
    .andIcon{
      width: 145px;
      height: 40px;
    }
  }

@media (max-width: 700px) {
  .heroHead {
    font-size: 35px;
    /* Font size for small screens (576px and below) */
  }

  .msgSubHead {
    font-size: 30px;
  }

  .txtStyle {
    font-size: 15px
  }

  .linkStyle {
    font-size: 15PX;
  }

  .msgImg {
    width: 35vw;
    height: 50vw;
    margin-left: 1vw;
  }

  .msgImg1 {
    width: 15vw;
    height: 15vw;
    margin-top: 10vw;
    margin-left: 1vw;
    flex-shrink: 0;

  }

  .msgImg2 {
    width: 35vw;
    height: 20vw;
  }

  @media (max-width: 1200px) {
    .msgImg {
      width: 70vw
    }
  }

}

@media (max-width: 991px) {
  .topConatiner{
    padding-inline: 10vw;
    background-image: url('../assets/Images/bk-header-img-mobile.svg');
  background-size: cover;
  background-position: center;
  }

  .heading {
      color: #FFFFFF;
    }

    .subHeading {
      color: #FFFFFF;
    }

    .background-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4='); /* White pixel data URI */
  background-size: cover; /* Cover the entire div */
  background-position: center;
}

.inner-image {
  width: 80vw;
  padding-top: 25px;
}

.head{
font-size: 30px !important;
}
}
</style>
