import { createApp } from 'vue';
import {MotionPlugin} from '@vueuse/motion'
import App from './App.vue';
import router from './router.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/fonts.css'
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';


const app = createApp(App);
console.log(app);
app.use(Toast, {
    position: POSITION.TOP_RIGHT,
    timeout: 3000,
  });
app.use(router);
app.use(MotionPlugin); 
app.mount('#app');
