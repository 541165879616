<template>

  <div class="container-fluid" style="background: linear-gradient(211deg, #000 39.56%, #362380 100%);">

    <footer class="text-center text-lg-start border border-white mt-xl-5 pt-4">
      <!-- Grid container -->
      <div class="container p-4">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 ">
            <h5 class="text-uppercase mb-2 heading">Gorex</h5>

            <ul class="list-unstyled mb-10">
              <li>
                <router-link to="/about-us" class="text-white footertxt">About Us</router-link>
                <!-- <a href="/about-us" class="text-white footertxt">About Us</a> -->
              </li>
              <li>
                <router-link to="/partnerships-soon" class="text-white footertxt">Partnerships</router-link>
                <!-- <a href="/partnerships-soon" class="text-white footertxt">Partnerships</a> -->
              </li>
              <li>
                <router-link to="/services-soon" class="text-white footertxt">Services</router-link>
                <!-- <a href="/services-soon" class="text-white footertxt">Services</a> -->
              </li>
              <li>
                <router-link to="/solutions-soon" class="text-white footertxt">Solutions</router-link>
                <!-- <a href="/solutions-soon" class="text-white footertxt">Solutions</a> -->
              </li>
              <li>
                <router-link to="/contact-us" class="text-white footertxt">Contact Us</router-link>
                <!-- <a href="/contact-us" class="text-white footertxt">Contact Us</a> -->
              </li>
            </ul>

            <div class="mt-20"
              style="display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;">

              <img src="../assets/Icons/ios.svg" alt="">
              <img src="../assets/Icons/android.svg" alt="">

            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 ">
            <h5 class="text-uppercase mb-2 heading">Services</h5>

            <ul class="list-unstyled">
              <li>
                <a href="/home" class="text-white footertxt">Coming Soon</a>
              </li>

            </ul>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <h5 class="text-uppercase mb-2 heading">Any Questions?</h5>

            <h6 class="mb-2 footertxt">Go and Relax Private Limited.</h6>

            <p class="mb-6 footertxt">Pakistan Address: 79 - Aurangzeb Block, Garden Town, Lahore, Pakistan.</p>
            <p class="mb-6 footertxt">Saudi Arabia Address: PO Box 13317, Othman Bin Affan, Al Nada District, Riyadh, Saudi Arabia.</p>
            



          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 col-md-6 mb-4 mb-lg-0" style="background: linear-gradient(27deg, #362380 3.01%, #000 88.15%); width: 600px;
                height: 260px; padding-block: 10px; border-radius: 15px;">
            <h5 class="text-uppercase mb-2 heading">Subscribe to our newsletter</h5>
            <p class="mb-4 footertxt">Get exclusive access to promotions, updates, and King and the industry insights
              delivered straight to your inbox.</p>
            <div class="form-outline form-white mb-4">
              <input type="email" id="form5Example2" placeholder="Email address..." class="form-control mb-4" />
              <button type="submit" class="btn btn-outline-white btn-block "
                style="color: #FFF; border: 1px solid white;">Subscribe</button>
            </div>

          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Grid container -->

      <!-- Copyright -->
      <div class="text-center p-3 border-top border-white"
        style="width: 100%; display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;">
        <img loading="lazy" alt="" src="../assets/Icons/logo.svg" />

        <!-- <p class="mb-2 footertxt ml-5">© 2023 - Gorex, All Rights Reserved.</p> -->

        <!-- <a class="text-white mb-2" href="https://mdbootstrap.com/">Privacy Policy</a> -->

        <div style="display: flex; gap: 20px; justify-content: center; align-items: center;">
    <a href="https://x.com/GorexPk" target="_blank" rel="noopener noreferrer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="icon"
        viewBox="0 0 24 24"
        style="width: 23px; height: 23px;"
      >
        <path
          d="M8.29 20.74c7.55 0 11.68-6.26 11.68-11.69 0-.18 0-.36-.01-.54A8.35 8.35 0 0022 6.37a8.18 8.18 0 01-2.36.65 4.11 4.11 0 001.8-2.26 8.22 8.22 0 01-2.6 1 4.08 4.08 0 00-6.96 3.72 11.56 11.56 0 01-8.39-4.25 4.07 4.07 0 001.26 5.45 4.01 4.01 0 01-1.85-.51v.05c0 1.92 1.37 3.52 3.18 3.88a4.07 4.07 0 01-1.84.07 4.08 4.08 0 003.81 2.83A8.2 8.2 0 012 18.58a11.53 11.53 0 006.29 1.85"
        />
      </svg>
    </a>
    <a href="https://www.facebook.com/gorexpk/" target="_blank" rel="noopener noreferrer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="icon"
        viewBox="0 0 24 24"
        style="width: 18px; height: 18px;"
      >
        <path
          d="M22.675 0H1.325C.593 0 0 .593 0 1.326V22.67c0 .733.593 1.326 1.326 1.326h11.497v-9.839H9.692V9.847h3.131V7.299c0-3.1 1.894-4.788 4.659-4.788 1.325 0 2.465.099 2.795.143v3.243l-1.918.001c-1.504 0-1.794.714-1.794 1.762v2.31h3.588l-.467 3.31h-3.121V24h6.116c.733 0 1.326-.593 1.326-1.326V1.326C24 .593 23.408 0 22.675 0z"
        />
      </svg>
    </a>
    <a href="https://www.instagram.com/gorexpk/" target="_blank" rel="noopener noreferrer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="icon"
        viewBox="0 0 24 24"
        style="width: 23px; height: 23px;"
      >
        <path
          d="M7.75 2a5.75 5.75 0 00-5.75 5.75v8.5A5.75 5.75 0 007.75 22h8.5A5.75 5.75 0 0022 16.25v-8.5A5.75 5.75 0 0016.25 2h-8.5zm0 2h8.5A3.75 3.75 0 0120 7.75v8.5A3.75 3.75 0 0116.25 20h-8.5A3.75 3.75 0 014 16.25v-8.5A3.75 3.75 0 017.75 4zM12 7a5 5 0 100 10 5 5 0 000-10zm0 2a3 3 0 110 6 3 3 0 010-6zm4.5-.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        />
      </svg>
    </a>
    <a
      href="https://www.linkedin.com/company/gorexpakistan/posts/?feedView=all"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="icon"
        viewBox="0 0 24 24"
         style="width: 19px; height: 19px;"
      >
        <path
          d="M19 0H5C2.24 0 0 2.24 0 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5zM8.88 18.3H6.3V10h2.58v8.3zM7.6 8.84c-.8 0-1.37-.57-1.37-1.34s.56-1.34 1.34-1.34 1.36.56 1.36 1.34c.01.77-.56 1.34-1.33 1.34zM19.3 18.3h-2.58v-4.27c0-1.02-.39-1.72-1.36-1.72-.74 0-1.18.5-1.37.99-.07.18-.09.44-.09.7v4.3H11.3s.03-7.16 0-7.92h2.58v1.12c.34-.52.95-1.26 2.29-1.26 1.66 0 2.92 1.08 2.92 3.4v4.66z"
        />
      </svg>
    </a>
  </div>

      </div>
      <!-- Copyright -->
    </footer>

  </div>
  <!-- End of .container -->

</template>

<script>
export default {
  name: 'FooterBar',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.border-white {
    border-color: none !important;
}
.border{
  border: none !important;
}
.heading {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footertxt {
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.icon {
  width: 20px;
  height: 20px;
  fill: white;
  transition: transform 0.3s ease, fill 0.3s ease;
}

.icon:hover {
  transform: scale(1.2);
  fill: #0077b5; /* Adjust hover color */
}
</style>
